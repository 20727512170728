<script setup lang="ts">

</script>

<template>
	<span style="text-decoration: line-through"><slot/></span>
</template>

<style scoped lang="scss">

</style>